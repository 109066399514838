<template>
  <v-main>
    <v-container fluid page>
      <v-form ref="form" autocomplete="off">
        <v-row align="center">
          <v-col cols="12">
            <h2 class="display-1 font-weight-light pt-5 pl-5">
              {{ $t('configuration.title') }}
            </h2>
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" colored-border type="error" elevation="2">
              <strong v-if="code">{{ code }}</strong>
              {{ message }}
            </v-alert>
          </v-col>

          <v-col cols="12" v-if="showAlert === true">
            <v-alert
              border="left"
              colored-border
              type="success"
              elevation="2"
              dismissible
              @input="showAlert = false"
            >
              <strong>201</strong>
              La configuración se ha actualizado correctamente.
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="sound_on_order"
                      label="Aviso sonoro al recibir un pedido"
                    ></v-checkbox>

                    <v-checkbox
                      class="mt-0"
                      v-model="sound_on_scan"
                      label="Aviso sonoro cuando un cliente escanea un código"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Tiempo sesión clientes (en segundos)"
                      v-model="client_expiration_time"
                      :rules="rules.client_expiration_time"
                      prepend-icon="mdi-timelapse"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      name="languages"
                      v-model="associated_languages"
                      :items="languages"
                      label="Idiomas disponibles"
                      item-value="id"
                      item-text="language"
                      prepend-icon="mdi-translate"
                      multiple
                      chips
                      deletable-chips
                      small-chips
                      :loading="loadingLanguages"
                      :rules="rules.languages"
                      @focus="loadLanguages"
                      @change="checkDefaultLanguage"
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      v-model="default_language"
                      :items="associated_languages"
                      prepend-icon="mdi-translate"
                      menu-props="auto"
                      label="Idioma por defecto"
                      item-value="id"
                      item-text="language"
                      :rules="rules.default_language"
                    ></v-select>
                  </v-col>

                  <!-- Buttons -->
                  <v-col cols="12" class="text-right">
                    <v-btn color="primary" @click.stop="save" class="ma-2"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import goTo from 'vuetify/lib/services/goto';

import { required } from '@/validators/required';
import { numeric } from '@/validators/numeric';

import EximyrLoading from '../components/EximyrLoading';

export default {
  mixins: [errors],

  components: { EximyrLoading },

  data: () => ({
    languagesLoaded: false,
    languages: [],
    default_language: null,
    associated_languages: [],

    sound_on_order: false,
    sound_on_scan: false,
    client_expiration_time: '',

    loading: false,
    loadingLanguages: false,

    showAlert: false,
  }),

  computed: {
    // Validation errors
    rules() {
      return {
        client_expiration_time: [required(this), numeric(this)],
        languages: [required(this)],
        default_language: [required(this)],
      };
    },
  },

  created() {
    // Load current restaurant data
    this.loading = true;

    this.$store
      .dispatch('config/load', this.$route.params.restaurant)
      .then(response => {
        // this.config = response.config;

        if (!response.config) {
          return;
        }

        this.client_expiration_time = response.config.client_expiration_time;
        this.sound_on_order = response.config.sound_on_order;
        this.sound_on_scan = response.config.sound_on_scan;

        if (response.languages) {
          this.associated_languages = response.languages;
          let language = this.associated_languages.find(
            l => l.code == response.config.locale
          );

          if (language) {
            this.default_language = language.id;
          }
        }
      })
      .catch(error => {
        this.storeError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    loadLanguages() {
      if (this.languagesLoaded) {
        return;
      }

      this.loadingLanguages = true;

      // Load all languages
      this.$store
        .dispatch('languages/load')
        .then(response => {
          this.clearErrors();
          this.languagesLoaded = true;
          this.languages = response.data;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loadingLanguages = false;
        });
    },

    checkDefaultLanguage() {
      /**
       * We must check the language so the validation
       * will be reset if there are no selected languages
       */
      let found = false;
      this.associated_languages.forEach(l => {
        if (l.id == this.default_language) {
          found = true;
        }
      });

      if (false === found) {
        this.default_language = null;
      }
    },

    getConfiguredLocale() {
      const language = this.associated_languages.find(
        l => l.id == this.default_language
      );

      return language ? language.code : '';
    },

    save() {
      // Show success message
      this.showAlert = false;

      // Validate the form
      if (false == this.$refs.form.validate()) {
        return;
      }

      // Save the data
      let payload = {
        // The restaurant
        restaurant: this.$route.params.restaurant,

        // Create languages array
        languages: this.associated_languages.map(l => l.id),

        // Create configuration
        config: {
          locale: this.getConfiguredLocale(),
          client_expiration_time: this.client_expiration_time,
          sound_on_order: this.sound_on_order,
          sound_on_scan: this.sound_on_scan,
        },
      };

      this.loading = true;
      this.$store
        .dispatch('config/update', payload)
        .then(response => {
          // Clear all errors
          this.clearErrors();

          // Show success message
          this.showAlert = true;

          // Set the config to the current selected restaurant
          this.$store.dispatch('restaurants/updateConfig', response.config);
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          goTo(0);
          this.loading = false;
        });
    },
  },
};
</script>
